export const ActionTypes = {
  SET_WEB3_PROVIDER: 'SET_WEB3_PROVIDER',
  SET_ADDRESS: 'SET_ADDRESS',
  SET_CHAIN_ID: 'SET_CHAIN_ID',
  RESET_WEB3_PROVIDER: 'RESET_WEB3_PROVIDER',
  SET_ARRAY: 'SET_ARRAY',
  SET_PAGE: 'SET_PAGE',
  SET_SORT: 'SET_SORT',
  SET_EXCH_IDS: 'SET_EXCH_IDS',
  SET_CLAIM_IDS: 'SET_CLAIM_IDS',
};

export function setprovider(data) {
  return {
    type: ActionTypes.SET_WEB3_PROVIDER,
    payload: data,
  };
}

export function disconnect() {
  return {
    type: ActionTypes.RESET_WEB3_PROVIDER,
  };
}

export function setArray(data) {
  return {
    type: ActionTypes.SET_ARRAY,
    payload: { array: data },
  };
}

export function setExchIds(data) {
  return {
    type: ActionTypes.SET_EXCH_IDS,
    payload: { exch_ids: data },
  };
}

export function setClaimIds(data) {
  return {
    type: ActionTypes.SET_CLAIM_IDS,
    payload: { claim_ids: data },
  };
}

export function setPage(data) {
  return {
    type: ActionTypes.SET_PAGE,
    payload: { page: data },
  };
}

export function setSort(data) {
  return {
    type: ActionTypes.SET_SORT,
    payload: { sort: data },
  };
}
