/* eslint-disable max-len */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import Img from 'react-cool-img';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter, faInstagram, faDiscord,
} from '@fortawesome/free-brands-svg-icons';
import storywatch from '../files/storywatch.png';
import Nav from '../components/nav';

const Welcome = (props) => {
  return (
    <div id="maincontainer">
      <Nav />
      <div id="storycontainer">
        <div id="s-gray" />
        <div id="s-gold" />
        <div id="s-bg">
          <div id="s-top">
            <div id="s-top-text">
              NIMANY CLUB is the first native NFT brand to launch a digital watch NFT that can be redeemed for a real watch, going from digital to physical. Unlike most of the NFT projects the physical watch is not just a promise, it has been designed and produced by luxury watch veterans.
            </div>
            <Img
              src={storywatch}
              className="story-watch"
            />
          </div>
          <div id="s-text-box">
            <div id="s-box-top">
              As former Creative Director of both Tourneau and Jacob & Co., industry veteran and <span style={{ color: '#E2B85B' }}>NIMANY CLUB Founder Nima Behnoud </span>has spent decades working with the world&apos;s leading luxury watch brands, having creatively directed high-profile campaigns including Leo
              Messi and Cristiano Ronaldo for Jacob & Co. Nima&apos;s creativity, market insight, and early entry into the NFT scene have uniquely positioned the brand to establish the first watch brand with its flag firmly planted in the metaverse.
            </div>
            <div id="s-box-bottom">
              Every NFT digital watch purchase effectively buys entry into the exclusive NIMANY CLUB. Those who hold onto their watch in the digital format will enjoy exclusive first access to merchandise drops, special values, and offers every forty-five to sixty days. Members have to keep some of their <span style={{ color: '#E2B85B' }}>NIMANY CLUB NFT </span>assets in their wallet to remain part of the club.
            </div>
          </div>
          <div id="s-footer-text">
            Copyright © 2022 NIMANY CLUB. All rights reserved.
          </div>
          <div id="s-footer-socials">
            <a href="https://twitter.com/nimany" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon style={{ color: 'white' }} icon={faTwitter} size="1x" />
            </a>
            <a href="https://discord.gg/RywPVaPtye" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon style={{ color: 'white' }} icon={faDiscord} size="1x" />
            </a>
            <a href="https://www.clubhouse.com/@nimany" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon style={{ color: 'white' }} icon={['far', 'hand-wave']} size="1x" />
            </a>
            <a href="https://www.instagram.com/nimany/?hl=en" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon style={{ color: 'white' }} icon={faInstagram} size="1x" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
