import { ActionTypes } from '../actions';

const initialState = {
  exch_ids: [],
  claim_ids: [],
};

const ExchangeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_EXCH_IDS:
      return {
        ...state,
        exch_ids: action.payload.exch_ids,
      };
    case ActionTypes.SET_CLAIM_IDS:
      return {
        ...state,
        claim_ids: action.payload.claim_ids,
      };
    default:
      return state;
  }
};

export default ExchangeReducer;
