import React from 'react';
import './styles/gallery/nav.scss';
import './styles/gallery/gallery.scss';
import './styles/gallery/code.scss';
import './styles/welcome/section1.scss';
import './styles/welcome/section3.scss';
import './styles/welcome/howtomint.scss';
import './styles/welcome/roadmap.scss';
import './styles/welcome/globals.scss';
import './styles/welcome/mint.scss';
import './styles/watch/watch.scss';
import './styles/exchange/exchange.scss';
import './styles/claim/claim.scss';
import './styles/story/story.scss';
import './styles/tos/tos.scss';
// import './styles/.scss';
import {
  BrowserRouter as Router, Route, Switch, NavLink,
} from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/pro-regular-svg-icons';
import Welcome from './screens/welcome';
// import Nav from './components/nav';
import Story from './screens/story';
import ToS from './screens/tos';
import Gallery from './screens/gallery';
// import Testing from './screens/testing';
import ScrollToTop from './scrolltotop';
import Watch from './screens/watch';
import mynft from './screens/mynft';
import Exchange from './screens/exchange';
import claim from './screens/claim';

library.add(far);

const FallBack = (props) => {
  return (
    <div id="fallbackcontainer">
      <div id="fallbacktext">URL Not Found</div>
      <NavLink id="fallbackbutton" to="/">Go Back Home</NavLink>
    </div>
  );
};

// const Placeholder = (props) => {
//   return (
//     <div>
//       <Nav />
//       <div id="placeholdercontainer">
//         <div id="phtitle">COMING SOON</div>
//         <div id="phtitle2">After all Watches are Revealed</div>
//         <NavLink id="placeholderbutton" to="/">Go Back Home</NavLink>
//       </div>
//     </div>

//   );
// };

const App = (props) => {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route exact path="/" component={Welcome} />
          <Route exact path="/story" component={Story} />
          <Route exact path="/ToS" component={ToS} />
          <Route exact path="/gallery" component={Gallery} />
          <Route exact path="/mywatches" component={mynft} />
          <Route exact path="/watch/:id" component={Watch} />
          <Route exact path="/exchange" component={Exchange} />
          <Route exact path="/claim" component={claim} />
          {/* <Route exact path="/test" component={Testing} /> */}
          <Route component={FallBack} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

export default App;
