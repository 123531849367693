/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { setArray, setPage, setSort } from '../actions/index';
import GalleryTile from '../components/gallery/GalleryTile';
import Nav from '../components/nav';
import metadata from '../files/metadata.json';
import useWindowDimensions from '../lib/windowdimensions';

const options = [
  { value: 'None', label: 'None' },
  { value: 'Ceramic', label: 'Ceramic' },
  { value: 'Freaks', label: 'Freaks' },
  { value: 'Steel', label: 'Steel' },
  { value: 'Gold', label: 'Gold' },
  { value: 'Unicorn', label: 'Unicorn' },
];

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: '#f5f5f5',
    borderColor: 'black',
    color: 'black',
  }),

  option: (provided) => ({
    ...provided,
    backgroundColor: '#f5f5f5',
    borderColor: 'black',
    color: 'black',
  }),

  menu: (provided) => ({
    ...provided,
    backgroundColor: '#f5f5f5',
    borderColor: 'black',
    color: 'black',
  }),

  dropdownIndicator: (provided) => ({
    ...provided,
    color: 'black',
  }),

  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: 'black',
  }),

};

const Gallery = (props) => {
  const [codes, setWatches] = useState(false);
  const [totalTiles, setTotalTiles] = useState(24);
  const [flip, setFlip] = useState('Ascending');
  const { height, width } = useWindowDimensions();
  //   const [page, setPage] = useState(0);
  //   const [array, setArray] = useState([...metadata.meta_array]);

  useEffect(() => {
    const numPerRow = Math.floor((width / 365));
    const total = numPerRow * 7;
    setTotalTiles(total);
    setWatches(props.array.slice((props.page * total), (props.page * total + total)).map((code) => {
      return (
        <GalleryTile key={code.id} code={code} />
      );
    }));
  }, [width]);

  const flipCodes = () => {
    if (flip === 'Ascending') {
      props.setArray(props.array.reverse());
      setWatches(props.array.slice((props.page * totalTiles), (props.page * totalTiles + totalTiles)).map((code) => {
        return <GalleryTile key={code.id} code={code} />;
      }));
      setFlip('Descending');
    } else {
      props.setArray(props.array.reverse());
      setWatches(props.array.slice((props.page * totalTiles), (props.page * totalTiles + totalTiles)).map((code) => {
        return <GalleryTile key={code.id} code={code} />;
      }));
      setFlip('Ascending');
    }
  };

  const sort = async (value) => {
    if (value.value === 'Ceramic') {
      const updatedArray = metadata.meta_array.filter((nft) => nft.attributes[12].value === 'Ceramic');
      props.setArray(updatedArray);
      setWatches(updatedArray.slice((0 * totalTiles), (0 * totalTiles + totalTiles)).map((code) => {
        return <GalleryTile key={code.id} code={code} />;
      }));
      props.setSort(options[1]);
    } else if (value.value === 'Freaks') {
      const updatedArray = metadata.meta_array.filter((nft) => nft.attributes[12].value === 'Freaks');
      props.setArray(updatedArray);
      setWatches(updatedArray.slice((0 * totalTiles), (0 * totalTiles + totalTiles)).map((code) => {
        return <GalleryTile key={code.id} code={code} />;
      }));
      props.setSort(options[1]);
    } else if (value.value === 'Steel') {
      const updatedArray = metadata.meta_array.filter((nft) => nft.attributes[12].value === 'Steel');
      props.setArray(updatedArray);
      setWatches(updatedArray.slice((0 * totalTiles), (0 * totalTiles + totalTiles)).map((code) => {
        return <GalleryTile key={code.id} code={code} />;
      }));
      props.setSort(options[1]);
    } else if (value.value === 'Gold') {
      const updatedArray = metadata.meta_array.filter((nft) => nft.attributes[12].value === 'Gold');
      props.setArray(updatedArray);
      setWatches(updatedArray.slice((0 * totalTiles), (0 * totalTiles + totalTiles)).map((code) => {
        return <GalleryTile key={code.id} code={code} />;
      }));
      props.setSort(options[1]);
    } else if (value.value === 'Unicorn') {
      const updatedArray = metadata.meta_array.filter((nft) => nft.attributes[12].value === 'Unicorn');
      props.setArray(updatedArray);
      setWatches(updatedArray.slice((0 * totalTiles), (0 * totalTiles + totalTiles)).map((code) => {
        return <GalleryTile key={code.id} code={code} />;
      }));
      props.setSort(options[1]);
    } else if (value.value === 'None') {
      const updatedArray = metadata.meta_array;
      props.setArray(updatedArray);
      setWatches(updatedArray.slice((0 * totalTiles), (0 * totalTiles + totalTiles)).map((code) => {
        return <GalleryTile key={code.id} code={code} />;
      }));
      props.setSort(options[1]);
    }
    props.setPage(0);
  };

  const nextPage = () => {
    if (props.page === 74) {
      setWatches(props.array.slice(((props.page + 1) * totalTiles), (4850)).map((code) => {
        return <GalleryTile key={code.id} code={code} />;
      }));
      props.setPage(props.page + 1);
    } else if (props.page === 75) {
      console.log('nope');
    } else {
      setWatches(props.array.slice(((props.page + 1) * totalTiles), ((props.page + 1) * totalTiles + totalTiles)).map((code) => {
        return <GalleryTile key={code.id} code={code} />;
      }));
      props.setPage(props.page + 1);
    }
  };

  const prevPage = () => {
    if (props.page > 0) {
      setWatches(props.array.slice(((props.page - 1) * totalTiles), ((props.page - 1) * totalTiles + totalTiles)).map((code) => {
        return <GalleryTile key={code.id} code={code} />;
      }));
      props.setPage(props.page - 1);
    }
  };

  return (
    <div>
      <Nav />
      <div id="gallery-container">
        <div id="filter-bar">
          <div id="sorttext">Filter By:</div>
          <Select
            styles={customStyles}
            defaultValue={props.currentsort}
            onChange={sort}
            options={options}
          />
          <div className="pagebutton" id="ascendbutton" onClick={flipCodes}>{flip}</div>
        </div>
        {codes}
        <div id="paging">
          <div className="pagebutton" onClick={prevPage}>Prev</div>
          <div className="pagebutton" onClick={nextPage}>Next</div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => (
  {
    array: state.gallery.array,
    page: state.gallery.page,
    currentsort: state.gallery.sort,
  }
);

export default connect(mapStateToProps, { setArray, setPage, setSort })(Gallery);
