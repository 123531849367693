/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import Img from 'react-cool-img';
import mm from '../../files/mm.png';
import eth from '../../files/eth.png';
import wlogo from '../../files/wlogo.png';

const section1 = (props) => {
  return (
    <div id="how-to-container">
      <div className="how-to-card">
        <Img
          src={mm}
          id="mm-logo"
        />
        <div id="t1-title">
          DOWNLOAD METAMASK
        </div>
        <div id="t1-text">
          Create a MetaMask Wallet using either a desktop computer or an iOS/Android mobile device. This will allow you to buy, sell, send and receive Ethereum.
        </div>
      </div>
      <div className="how-to-card">
        <Img
          src={eth}
          id="eth-logo"
        />
        <div id="t2-title">FUND YOUR WALLET</div>
        <div id="t2-text-1">
          You can buy Ethereum (ETH) directly on MetaMasks or transfer it to your MetaMask or transfer it to your MetaMask Wallet from exchanges like Coinbase.
        </div>
        <div id="t2-text-2">Ensure you are using the ERC-20 network when transferring ETH.</div>
      </div>
      <div className="how-to-card">
        <Img
          src={wlogo}
          id="wlogo"
        />
        <div id="t3-title">CONNECT YOUR WALLET</div>
        <div id="t3-text">
          Click the MINT button at the top of this website. When prompted, connect your wallet and pay the 0.08 ETH minting fee in addition to any gas fees associated with the transaction.
        </div>
      </div>
    </div>
  );
};

export default section1;
