/* eslint-disable max-len */
import React from 'react';
import Img from 'react-cool-img';
import unicorn from '../../files/unicorn.png';
import freak from '../../files/freak.png';
import golds from '../../files/golds.png';
import steel from '../../files/steel.png';
import ceramic from '../../files/ceramic.png';

const section1 = (props) => {
  return (
    <div id="section3container">
      <div id="s3-top-text">
        NIMANY Club Watch NFT Holders Benefits
      </div>
      <div className="s3-containers-left">
        <div className="s3-watch-name-left">UNICORN</div>
        <Img
          className="s3-watch-left"
          src={unicorn}
        />
        <div className="s3-watch-descrip-right">
          <span className="s3-bold">1- Unicorn Holders:</span>{' '}The rarest and most sought out piece in the collection: the Unicorn NFT. Made of monochromatic material, holder&apos;s of the Unicorn NFT watch will automatically be eligible to receive their randomly picked luxury NIMANY Club physical watch and have access to continuous NIMANY Club Perks and surprise gifts.
        </div>
      </div>
      <div className="s3-containers-right">
        <Img
          className="s3-watch-right"
          src={golds}
        />
        <div className="s3-watch-descrip-left">
          <span className="s3-bold">2- Gold Watch Holders:</span>{' '}Owning an exclusive Gold NFT will allow you to redeem a randomly picked physical watch by burning your Gold NFT and have access to continuous NIMANY Club perks and surprise gifts every season.
        </div>
        <div className="s3-watch-name-right">GOLDS</div>
      </div>
      <div className="s3-containers-left">
        <div className="s3-watch-name-left">FREAKS</div>
        <Img
          className="s3-watch-left"
          src={freak}
        />
        <div className="s3-watch-descrip-right">
          <span className="s3-bold">3- Freak Watch Holders:</span>{' '}The unique Freak watch is designed and produced only in the metaverse, using the most uncommon and unusual materials. The freak watch NFT is the only category that can receive an airdrop of another random watch NFT at phase 6. In addition Each Freak watch NFT equals 3 ceramic watch during the burn phase 3, or they be kept for future merchandise drops and surprises every season.

        </div>
      </div>
      <div className="s3-containers-right">

        <Img
          className="s3-watch-right"
          src={steel}
        />
        <div className="s3-watch-descrip-left">
          <span className="s3-bold">4- Steel watch Holders:</span>{' '}The classic, yet modern Steel NFT triumphs as the most resilient of the collection. Collectors
          can upgrade their NFTs by burning 2 steel watches in exchange for 1 Gold watch NFT to have the opportunity to
          upgrade to the gold level.
        </div>
        <div className="s3-watch-name-right">STEELS</div>
      </div>
      <div className="s3-containers-left">
        <div className="s3-watch-name-left">CERAMICS</div>
        <Img
          className="s3-watch-left"
          src={ceramic}
        />
        <div className="s3-watch-descrip-right">
          <span className="s3-bold">5- Ceramic Watches Holder:</span>{' '}The most common and the most colorful in the collection. Collectors can upgrade their
          NFTs; 2 Ceramic watches can turn into 1 steel watch, 3 Ceramic watches can turn into a Freak watch and 4 ceramic
          watches can turn into a Gold watch.

        </div>
      </div>
      <div id="burn-table">
        <div id="table-header">
          <div className="row-l">BURN</div>
          <div style={{ color: '#262627' }} className="row-c">.</div>
          <div className="row-r">TO RECEIVE</div>
        </div>
        <div className="table-row">
          <div className="row-l">2 CERAMIC</div>
          <div className="row-c">=</div>
          <div className="row-r">1 STEEL</div>
        </div>
        <div className="table-row">
          <div className="row-l">4 CERAMIC</div>
          <div className="row-c">=</div>
          <div className="row-r">1 GOLD</div>
        </div>
        <div className="table-row">
          <div className="row-l">3 CERAMIC</div>
          <div className="row-c">=</div>
          <div className="row-r">1 FREAK</div>
        </div>
        <div className="table-row">
          <div className="row-l">2 STEEL</div>
          <div className="row-c">=</div>
          <div className="row-r">1 GOLD</div>
        </div>
      </div>
    </div>
  );
};

export default section1;
