/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import Img from 'react-cool-img';
import { connect } from 'react-redux';

const GalleryTile = (props) => {
  return (
    <div className={`exchange-watch-tile ${props.exch_ids.includes(props.code.id) ? 'e-selected' : ''}`}>
      <Img
        className="e-watch-img"
        src={`https://nimany-thumbs.s3.us-east-2.amazonaws.com/${props.code.id}.jpg`}
      />
      <div className="e-tile-name">
        {`${props.code.name}`}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => (
  {
    exch_ids: state.exchange.exch_ids,
  }
);

export default connect(mapStateToProps, null)(GalleryTile);
