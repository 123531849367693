/* eslint-disable max-len */
import React from 'react';
import Img from 'react-cool-img';
import { useMediaQuery } from 'react-responsive';
import clock from '../../files/clock.png';
import mobileroad from '../../files/mobileroad.png';

const roadmap = (props) => {
  const isNotMobile = useMediaQuery({ query: '(min-width: 1000px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 999px)' });
  return (
    <div id="roadmapcontainer">
      {isNotMobile && (
      <div id="clock-relative">
        <Img
          src={clock}
          id="rm-clock"
        />
        <div id="phase0" className="clockphase">
          <div className="phase-title">Crafting the Watches</div>
          <div className="phase-time">Phase 00:00</div>
        </div>
        <div id="phase1" className="clockphase">
          <div className="phase-title">Minting</div>
          <div className="phase-time">Phase 01:00</div>
          <div className="phase-text">Minting will open on April 28, 2022.</div>
        </div>
        <div id="phase2" className="clockphase">
          <div className="phase-title">Reveal NFTs</div>
          <div className="phase-time">Phase 02:00</div>
          <div className="phase-text">Watches are revealed. Detailed breakdown available in the &quot;My Watches&quot; Page</div>
        </div>
        <div id="phase3" className="clockphase">
          <div className="phase-title">Exchanging NFTs</div>
          <div className="phase-time">Phase 03:00</div>
          <div className="phase-text">Holder&apos;s with a Ceramic, Freak, or Steel watch NFT can burn their NFTs to exchange them for a higher rarity, or keep them for more surprises.
          </div>
        </div>
        <div id="phase4" className="clockphase">
          <div className="phase-title">Redeem NFTs for a Physical Watch</div>
          <div className="phase-time">Phase 04:00</div>
          <div className="phase-text">Gold Watch NFTs can be burned in exchange for a randomly picked, high-end physical watch. You will receive a Gold Membership NFT that indicates you as a Physical watch holder and keeps you eligible for further benefits of the NIMANY Club.
          </div>
        </div>
        <div id="phase5" className="clockphase">
          <div className="phase-title">Unicorn Time</div>
          <div className="phase-time">Phase 05:00</div>
          <div style={{ width: '140px' }} className="phase-text">Owners of Unicorn watch NFTs can
            redeem their randomly picked
            physical watch without having to
            burn.
          </div>
        </div>
        <div id="phase6" className="clockphase">
          <div className="phase-title">Getting Freaky</div>
          <div className="phase-time">Phase 06:00</div>
          <div className="phase-text">Freak watch holders will receive
            an additional Watch NFT via
            airdrop.
          </div>
        </div>
        <div id="phase7" className="clockphase">
          <div className="phase-title">Special Gifts</div>
          <div className="phase-time">Phase 07:00</div>
          <div className="phase-text">
            Owners of Unicorn, Gold, Steel, and Freak watch NFTs will recieve merchandise, matched to their NFT&apos;s rarity*.
          </div>
        </div>
        <div id="phase8" className="clockphase">
          <div className="phase-title">Club Expansion</div>
          <div className="phase-time">Phase 08:00</div>
          <div className="phase-text">
            Announcement of a new collection exclusive to Nimany Club members.
          </div>
        </div>
        <div id="phase9" className="clockphase">
          <div className="phase-title">Season 2</div>
          <div className="phase-time">Phase 09:00</div>
          <div className="phase-text">
            NIMANY Club members will have access to the next season of NIMANY Luxury NFT collection.
          </div>
        </div>
        <div id="phase10" className="clockphase">
          <div className="phase-title">Season 3</div>
          <div className="phase-time">Phase 10:00</div>
        </div>
        <div id="phase11" className="clockphase">
          <div className="phase-title">Season 4</div>
          <div className="phase-time">Phase 11:00</div>
        </div>
        <div id="r-title">
          NIMANY CLUB WATCHES ROADMAP
        </div>
      </div>
      )}
      {isMobile && (
      <div id="mobile-rm-container">
        <Img
          src={mobileroad}
          id="rm-vert-clock"
        />
        <div id="r-m-title">
          NIMANY CLUB WATCHES ROADMAP
        </div>
        <div id="mobile-rm-text">
          <div id="phase0-m" className="clockphase">
            <div className="phase-title">Crafting the Watches</div>
            <div className="phase-time">Phase 00:00</div>
          </div>
          <div id="phase1-m" className="clockphase">
            <div className="phase-title">Minting</div>
            <div className="phase-time">Phase 01:00</div>
            <div className="phase-text">Minting will open on April 28, 2022.</div>
          </div>
          <div id="phase2-m" className="clockphase">
            <div className="phase-title">Reveal NFTs</div>
            <div className="phase-time">Phase 02:00</div>
            <div className="phase-text">Watches are revealed. Detailed breakdown available in the &quot;My Watches&quot; Page</div>
          </div>
          <div id="phase3-m" className="clockphase">
            <div className="phase-title">Exchanging NFTs</div>
            <div className="phase-time">Phase 03:00</div>
            <div className="phase-text">Holder&apos;s with a Ceramic, Freak, or Steel watch NFT can burn their NFTs to exchange them for a higher rarity, or keep them for more surprises.
            </div>
          </div>
          <div id="phase4-m" className="clockphase">
            <div className="phase-title">Redeem NFTs for a Physical Watch</div>
            <div className="phase-time">Phase 04:00</div>
            <div style={{ width: '230px' }} className="phase-text">Gold Watch NFTs can be burned in exchange for a randomly picked, high-end physical watch. You will receive a Gold Membership NFT that indicates you as a Physical watch holder and keeps you eligible for further benefits of the NIMANY Club.
            </div>
          </div>
          <div id="phase5-m" className="clockphase">
            <div className="phase-title">Unicorn Time</div>
            <div className="phase-time">Phase 05:00</div>
            <div className="phase-text">Owners of Unicorn watch NFTs can
              redeem their randomly picked
              physical watch without having to
              burn.
            </div>
          </div>
          <div id="phase6-m" className="clockphase">
            <div className="phase-title">Getting Freaky</div>
            <div className="phase-time">Phase 06:00</div>
            <div className="phase-text">Freak watch holders will receive
              an additional Watch NFT via
              airdrop.
            </div>
          </div>
          <div id="phase7-m" className="clockphase">
            <div className="phase-title">Special Gifts</div>
            <div className="phase-time">Phase 07:00</div>
            <div className="phase-text">
              Owners of Unicorn, Gold, Steel, and Freak watch NFTs will recieve merchandise, matched to their NFT&apos;s rarity*.
            </div>
          </div>
          <div id="phase8-m" className="clockphase">
            <div className="phase-title">Club Expansion</div>
            <div className="phase-time">Phase 08:00</div>
            <div className="phase-text">
              Announcement of a new collection exclusive to Nimany Club members.
            </div>
          </div>
          <div id="phase9-m" className="clockphase">
            <div className="phase-title">Season 2</div>
            <div className="phase-time">Phase 09:00</div>
            <div className="phase-text">
              NIMANY Club members will have access to the next season of NIMANY Luxury NFT collection.
            </div>
          </div>
          <div id="phase10-m" className="clockphase">
            <div className="phase-title">Season 3</div>
            <div className="phase-time">Phase 10:00</div>
          </div>
          <div id="phase11-m" className="clockphase">
            <div className="phase-title">Season 4</div>
            <div className="phase-time">Phase 11:00</div>
          </div>
        </div>
      </div>

      )}
    </div>
  );
};

export default roadmap;
// <div></div>
