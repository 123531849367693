/* eslint-disable max-len */
import React from 'react';
import Img from 'react-cool-img';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter, faInstagram, faDiscord, faTelegram,
} from '@fortawesome/free-brands-svg-icons';
import Marquee from 'react-fast-marquee';
import watch from '../../files/white.png';
import vid from '../../files/3d.mp4';
import carousel1 from '../../files/carousel1.png';
import carousel2 from '../../files/carousel2.png';
import carousel3 from '../../files/carousel3.png';
import carousel4 from '../../files/carousel4.png';
import carousel5 from '../../files/carousel5.png';
import carousel6 from '../../files/carousel6.png';
import carousel7 from '../../files/carousel7.png';
import carousel8 from '../../files/carousel8.png';
import carousel9 from '../../files/carousel9.png';
import carousel10 from '../../files/carousel10.png';
import carousel11 from '../../files/carousel11.png';
import carousel12 from '../../files/carousel12.png';
import carousel13 from '../../files/carousel13.png';
import carousel14 from '../../files/carousel14.png';
import carousel15 from '../../files/carousel15.png';
import MintButton from './mintbutton';

const section1 = (props) => {
  return (
    <div id="section1container">
      <Img
        id="whitewatch"
        src={watch}
      />
      <div id="s1-logo" />
      <div id="s1-title-2">1810 UNIQUE NFT WATCHES</div>
      <div id="datesbox">
        <div id="s1-title-3">PRESALE: ENDED</div>
        <div id="double-line">||</div>
        <div id="s1-title-4">PUBLIC: LIVE</div>
      </div>
      <MintButton />
      <div id="s1-text-1">A Project by Nima Behnoud, former creative director of
        Jacob & Co. Watches & Artistic Director of Tourneau
        Watches. Nima uses 15 years of design experience in watch
        industry to create a unique collection of NFT&apos;s.
      </div>
      <div id="placeholder-box">
        <video playsInline autoPlay muted loop id="s1-gif">
          <source src={vid} type="video/mp4" />
        </video>
        <div id="placeholder-text">
          <div id="p-text-1">“Today most people have digital identities across platforms.
            The lines between digital and physical realities are blurring. I
            believe people are looking for ways to enhance their digital
            identities as they would in the physical world. NIMANY
            CLUB is offering the appealing scarcity of traditional goods
            while exploring the limitless creative potential of NFTs.”
          </div>
          <div id="p-title">- Nima Behnoud</div>
        </div>
      </div>
      <div id="s1-transparent-box">
        <div id="s1-transparent-text">
          Every NFT digital watch purchase effectively buys entry into
          the exclusive NIMANY CLUB. Those who hold onto their
          watch in the digital format will enjoy exclusive first access to
          merchandise drops

        </div>
      </div>
      <Marquee style={{ marginBottom: '30px' }} gradient={false} speed={20}>
        <Img
          style={{ width: '370px' }}
          src={carousel1}
        />
        <Img
          style={{ width: '370px' }}
          src={carousel2}
        />
        <Img
          style={{ width: '370px' }}
          src={carousel3}
        />
        <Img
          style={{ width: '370px' }}
          src={carousel4}
        />
        <Img
          style={{ width: '370px' }}
          src={carousel5}
        />
        <Img
          style={{ width: '370px' }}
          src={carousel6}
        />
        <Img
          style={{ width: '370px' }}
          src={carousel7}
        />
        <Img
          style={{ width: '370px' }}
          src={carousel8}
        />
        <Img
          style={{ width: '370px' }}
          src={carousel9}
        />
        <Img
          style={{ width: '370px' }}
          src={carousel10}
        />
        <Img
          style={{ width: '370px' }}
          src={carousel11}
        />
        <Img
          style={{ width: '370px' }}
          src={carousel12}
        />
        <Img
          style={{ width: '370px' }}
          src={carousel13}
        />
        <Img
          style={{ width: '370px' }}
          src={carousel14}
        />
        <Img
          style={{ width: '370px' }}
          src={carousel15}
        />
      </Marquee>
      <div id="topsocials" className="socials">
        <a className="socialicon" href="https://www.instagram.com/nimany/?hl=en" target="_blank" rel="noopener noreferrer">
          <div className="socialinner">
            <FontAwesomeIcon icon={faInstagram} size="1x" />
          </div>
        </a>
        <a className="socialicon" href="https://twitter.com/nimany" target="_blank" rel="noopener noreferrer">
          <div className="socialinner">
            <FontAwesomeIcon icon={faTwitter} size="1x" />
          </div>
        </a>
        <a className="socialicon" href="https://www.clubhouse.com/@nimany" target="_blank" rel="noopener noreferrer">
          <div className="socialinner">
            <FontAwesomeIcon icon={['far', 'hand-wave']} size="1x" />
          </div>
        </a>
        <a className="socialicon" href="https://discord.gg/RywPVaPtye" target="_blank" rel="noopener noreferrer">
          <div className="socialinner">
            <FontAwesomeIcon icon={faDiscord} size="1x" />
          </div>
        </a>
        <a className="socialicon" href="https://t.me/+cEpvjllGMlBkZjEx" target="_blank" rel="noopener noreferrer">
          <div className="socialinner">
            <FontAwesomeIcon icon={faTelegram} size="1x" />
          </div>
        </a>
      </div>
    </div>
  );
};

export default section1;
