/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import Material from '../components/material';
import Nav from '../components/nav';
import metadata from '../files/metadata.json';

const Watch = (props) => {
  const [open, setOpen] = useState(0);
  const watch = metadata.meta_array[props.match.params.id];
  const crownjewel = watch.attributes[0].value;
  const crown = watch.attributes[1].value;
  const dial = watch.attributes[2].value;
  const logo = watch.attributes[3].value;
  const secondsmarker = watch.attributes[4].value;
  const luminousmarker = watch.attributes[5].value;
  const hourmarker = watch.attributes[6].value;
  const strap = watch.attributes[7].value;
  const lugs = watch.attributes[8].value;
  const innerbezel = watch.attributes[9].value;
  const outerbezel = watch.attributes[10].value;
  const hand = watch.attributes[11].value;

  return (
    <div>
      <Nav />
      <div id="watch-details-container">
        <img id="watch-image" src={watch.image} alt={watch.name} />
        <div id="w-d-tiles">
          <div className="w-d-tile"
            style={{
              cursor: 'default', width: '90%', color: 'white', backgroundColor: 'black', fontWeight: '500', fontSize: '22px', paddingTop: '14px', paddingBottom: '14px',
            }}
          >{watch.name}
          </div>
          <div className="w-d-tile"
            onClick={() => {
              if (open !== 1) {
                setOpen(1);
              } else {
                setOpen(0);
              }
            }}
          >
            <div className="w-d-title">Crown Jewel</div>
            <div className="w-d-part">({crownjewel})</div>
            <div className={(open === 1 ? 'active-tile' : 'inactive-tile')}>
              <Material material={crownjewel} />
            </div>
          </div>
          <div className="w-d-tile"
            onClick={() => {
              if (open !== 2) {
                setOpen(2);
              } else {
                setOpen(0);
              }
            }}
          >
            <div className="w-d-title">Crown</div>
            <div className="w-d-part">({crown})</div>
            <div className={(open === 2 ? 'active-tile' : 'inactive-tile')}>
              <Material material={crown} />
            </div>
          </div>
          <div className="w-d-tile"
            onClick={() => {
              if (open !== 3) {
                setOpen(3);
              } else {
                setOpen(0);
              }
            }}
          >
            <div className="w-d-title">Dial</div>
            <div className="w-d-part">({dial})</div>
            <div className={(open === 3 ? 'active-tile' : 'inactive-tile')}>
              <Material material={dial} />
            </div>
          </div>
          <div className="w-d-tile"
            onClick={() => {
              if (open !== 4) {
                setOpen(4);
              } else {
                setOpen(0);
              }
            }}
          >
            <div className="w-d-title">Logo</div>
            <div className="w-d-part">({logo})</div>
            <div className={(open === 4 ? 'active-tile' : 'inactive-tile')}>
              <Material material={logo} />
            </div>
          </div>
          <div className="w-d-tile"
            onClick={() => {
              if (open !== 5) {
                setOpen(5);
              } else {
                setOpen(0);
              }
            }}
          >
            <div className="w-d-title">Seconds Marker</div>
            <div className="w-d-part">({secondsmarker})</div>
            <div className={(open === 5 ? 'active-tile' : 'inactive-tile')}>
              <Material material={secondsmarker} />
            </div>
          </div>
          <div className="w-d-tile"
            onClick={() => {
              if (open !== 6) {
                setOpen(6);
              } else {
                setOpen(0);
              }
            }}
          >
            <div className="w-d-title">Luminous Marker</div>
            <div className="w-d-part">({luminousmarker})</div>
            <div className={(open === 6 ? 'active-tile' : 'inactive-tile')}>
              <Material material={luminousmarker} />
            </div>
          </div>
          <div className="w-d-tile"
            onClick={() => {
              if (open !== 7) {
                setOpen(7);
              } else {
                setOpen(0);
              }
            }}
          >
            <div className="w-d-title">Hour Marker</div>
            <div className="w-d-part">({hourmarker})</div>
            <div className={(open === 7 ? 'active-tile' : 'inactive-tile')}>
              <Material material={hourmarker} />
            </div>
          </div>
          <div className="w-d-tile"
            onClick={() => {
              if (open !== 8) {
                setOpen(8);
              } else {
                setOpen(0);
              }
            }}
          >
            <div className="w-d-title">Strap</div>
            <div className="w-d-part">({strap})</div>
            <div className={(open === 8 ? 'active-tile' : 'inactive-tile')}>
              <Material material={strap} />
            </div>
          </div>
          <div className="w-d-tile"
            onClick={() => {
              if (open !== 9) {
                setOpen(9);
              } else {
                setOpen(0);
              }
            }}
          >
            <div className="w-d-title">Lugs</div>
            <div className="w-d-part">({lugs})</div>
            <div className={(open === 9 ? 'active-tile' : 'inactive-tile')}>
              <Material material={lugs} />
            </div>
          </div>
          <div className="w-d-tile"
            onClick={() => {
              if (open !== 10) {
                setOpen(10);
              } else {
                setOpen(0);
              }
            }}
          >
            <div className="w-d-title">Inner Bezel</div>
            <div className="w-d-part">({innerbezel})</div>
            <div className={(open === 10 ? 'active-tile' : 'inactive-tile')}>
              <Material material={innerbezel} />
            </div>
          </div>
          <div className="w-d-tile"
            onClick={() => {
              if (open !== 11) {
                setOpen(11);
              } else {
                setOpen(0);
              }
            }}
          >
            <div className="w-d-title">Outer Bezel</div>
            <div className="w-d-part">({outerbezel})</div>
            <div className={(open === 11 ? 'active-tile' : 'inactive-tile')}>
              <Material material={outerbezel} />
            </div>
          </div>
          <div className="w-d-tile"
            onClick={() => {
              if (open !== 12) {
                setOpen(12);
              } else {
                setOpen(0);
              }
            }}
          >
            <div className="w-d-title">Hand</div>
            <div className="w-d-part">({hand})</div>
            <div className={(open === 12 ? 'active-tile' : 'inactive-tile')}>
              <Material material={hand} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Watch;
