/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
// import Nav from '../components/nav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavHashLink } from 'react-router-hash-link';
import {
  faTwitter, faInstagram, faDiscord,
} from '@fortawesome/free-brands-svg-icons';
import Section1 from '../components/welcome/section1';
import Roadmap from '../components/welcome/roadmap';
import Section3 from '../components/welcome/section3';
import HowTo from '../components/welcome/howtomint';

import Nav from '../components/nav';

const Welcome = (props) => {
  return (
    <div id="maincontainer">
      <Nav />
      <Section1 />
      <Roadmap />
      <div style={{ width: '100vw', height: '35px', backgroundColor: 'lightgray' }} />
      <Section3 />
      <div style={{ width: '100vw', height: '35px', backgroundColor: 'lightgray' }} />
      <HowTo />
      <div style={{ width: '100vw', height: '35px', backgroundColor: 'lightgray' }} />
      <div id="f-container">
        <a href="https://layerr.xyz" target="_blank" rel="noopener noreferrer">
          <div id="layerr-branding">
            <div id="layerr-txt">Powered by </div>
            <div id="layerrlogo" />
          </div>
        </a>
        <div id="s3-line" />
        <div id="footer-socials">
          <a className="socialicon" href="https://twitter.com/nimany" target="_blank" rel="noopener noreferrer">
            <div className="socialinner">
              <FontAwesomeIcon icon={faTwitter} size="1x" />
            </div>
          </a>
          <a className="socialicon" href="https://discord.gg/RywPVaPtye" target="_blank" rel="noopener noreferrer">
            <div className="socialinner">
              <FontAwesomeIcon icon={faDiscord} size="1x" />
            </div>
          </a>
          <a className="socialicon" href="https://www.clubhouse.com/@nimany" target="_blank" rel="noopener noreferrer">
            <div className="socialinner">
              <FontAwesomeIcon icon={['far', 'hand-wave']} size="1x" />
            </div>
          </a>
          <a className="socialicon" href="https://www.instagram.com/nimany/?hl=en" target="_blank" rel="noopener noreferrer">
            <div className="socialinner">
              <FontAwesomeIcon icon={faInstagram} size="1x" />
            </div>
          </a>
        </div>
        <div id="footer-text">
          Copyright © 2022 NIMANY CLUB. All rights reserved.
        </div>
        <NavHashLink
          smooth
          style={{ marginBottom: '55px', textDecoration: 'underline' }}
          to="/ToS"
          activeClassName="selected"
        >Terms of Service
        </NavHashLink>
      </div>
    </div>
  );
};

export default Welcome;
