/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter, faInstagram, faDiscord,
} from '@fortawesome/free-brands-svg-icons';
import axios from 'axios';
import { ethers } from 'ethers';
import { setExchIds } from '../actions';
import Nav from '../components/nav';
import metadata from '../files/metadata.json';
import ExchangeTile from '../components/exchange/ExchangeTile';
import abi from '../files/NIMANYClubWatches.json';

const IS_PROD = true;
const CONTRACT_ADDRESS = IS_PROD ? '0x740e80CE6E3956733bC9833a74233C8fdD36F761' : '0xFF6F627dD2F29eedfD240982A17cF65704078345';

const options1 = [
  { value: 'None', label: 'None' },
  { value: '1 Freak + 1 Ceramic', label: '1 Freak + 1 Ceramic' },
  { value: '2 Ceramic', label: '2 Ceramic' },
  { value: '4 Ceramic', label: '4 Ceramic' },
  { value: '2 Steel', label: '2 Steel' },
  { value: '3 Ceramic', label: '3 Ceramic' },
];

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: '#f5f5f5',
    width: '200px',
    borderColor: '#f5f5f5',
    boxShadow: '10px 5px 10px 0px #7e7e7e54, -4px -4px 15px 0px rgb(255, 255, 255)',
    color: 'black',
    borderRadius: '10px',
    transition: '1s ease-in-out',
    '&:hover': {
      boxShadow: '4px 4px 15px 0px #ffffff, -8px -5px 10px 0px #7e7e7e54',
    },
  }),

  option: (provided) => ({
    ...provided,
    backgroundColor: '#f5f5f5',
    borderColor: 'black',
    color: 'black',
  }),

  menu: (provided) => ({
    ...provided,
    backgroundColor: '#f5f5f5',
    borderColor: 'black',
    color: 'black',
  }),

  dropdownIndicator: (provided) => ({
    ...provided,
    color: 'black',
  }),

  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: 'black',
  }),

};

const numToBurn = {
  '1 Freak + 1 Ceramic': 2,
  '2 Ceramic': 2,
  '4 Ceramic': 4,
  '2 Steel': 2,
  '3 Ceramic': 3,
};

const Exchange = (props) => {
  const [watches, setWatches] = useState(<div id="e-message">Please connect your wallet to view your watches.</div>);
  const [selOption, setSelOption] = useState('none');
  const [output, setOutput] = useState('');
  let selectedWatches = [];

  const select = (id, option) => {
    if (selectedWatches.length < 2) {
      if (!selectedWatches.includes(id)) {
        selectedWatches.push(id);
        const arrcopy = JSON.parse(JSON.stringify(selectedWatches));
        props.setExchIds(arrcopy);
      }
    } else if (selectedWatches.length < numToBurn[option]) {
      if (!selectedWatches.includes(id)) {
        selectedWatches.push(id);
        const arrcopy = JSON.parse(JSON.stringify(selectedWatches));
        props.setExchIds(arrcopy);
      }
    } else if (!selectedWatches.includes(id)) {
      selectedWatches.shift();
      selectedWatches.push(id);

      const arrcopy = JSON.parse(JSON.stringify(selectedWatches));
      props.setExchIds(arrcopy);
    }
  };

  let contract;
  if (props.web3Provider) {
    try {
      contract = new ethers.Contract(
        CONTRACT_ADDRESS,
        abi.abi,
        props.web3Provider.getSigner(),
      );
    } catch (err) {
      console.log(err);
      console.error('Error initializing contract');
    }
  }

  useEffect(async () => {
    selectedWatches = props.exch_ids;
    if (!props.web3Provider) {
      setWatches(<div id="e-message">Please connect your wallet to view your watches.</div>);
    } else if (selOption !== 'none') {
      try {
        contract = new ethers.Contract(
          CONTRACT_ADDRESS,
          abi.abi,
          props.web3Provider.getSigner(),
        );
      } catch (err) {
        console.log(err);
        console.error('Error initializing contract');
      }

      const watches1 = [];
      let userinfo;
      try {
        userinfo = await axios.get(`https://api.covalenthq.com/v1/1/address/${props.address}/balances_v2/?quote-currency=USD&format=JSON&nft=true&no-nft-fetch=false&key=ckey_6c3514efa34e4ea6a62f2e95df5`);
      } catch (err) {
        console.dir(err);
      }
      if (selOption === '1 Freak + 1 Ceramic') {
        setOutput('1 Gold');
      } else if (selOption === '2 Ceramic') {
        setOutput('1 Steel');
      } else if (selOption === '4 Ceramic') {
        setOutput('1 Gold');
      } else if (selOption === '2 Steel') {
        setOutput('1 Gold');
      } else if (selOption === '3 Ceramic') {
        setOutput('1 Freak');
      }
      const tokenarray = userinfo.data.data.items;
      for (let i = 0; i < tokenarray.length; i += 1) {
        const token = tokenarray[i];
        if (token.contract_name === 'NIMANYClubWatches') {
          for (let j = 0; j < token.nft_data.length; j += 1) {
            const nft = token.nft_data[j];
            const metadata1 = metadata.meta_array[nft.token_id];

            if (selOption === '1 Freak + 1 Ceramic') {
              if (metadata1.attributes[12].value === 'Ceramic' || metadata1.attributes[12].value === 'Freaks') {
                watches1.push(metadata1);
              }
            } else if (selOption === '2 Ceramic') {
              if (metadata1.attributes[12].value === 'Ceramic') {
                watches1.push(metadata1);
              }
            } else if (selOption === '4 Ceramic') {
              if (metadata1.attributes[12].value === 'Ceramic') {
                watches1.push(metadata1);
              }
            } else if (selOption === '2 Steel') {
              if (metadata1.attributes[12].value === 'Steel') {
                watches1.push(metadata1);
              }
            } else if (selOption === '3 Ceramic') {
              if (metadata1.attributes[12].value === 'Ceramic') {
                watches1.push(metadata1);
              }
            }
          }
        }
      }

      if (watches1.length > 0) {
        setWatches(watches1.map((watch) => {
          return (
            <div key={watch.id} onClick={() => { select(watch.id, selOption); }}>
              <ExchangeTile code={watch} />
            </div>
          );
        }));
      } else {
        setWatches(<div id="e-message">We could not find any watches of this type for your address.</div>);
      }
    } else {
      try {
        contract = new ethers.Contract(
          CONTRACT_ADDRESS,
          abi.abi,
          props.web3Provider.getSigner(),
        );
      } catch (err) {
        console.log(err);
        console.error('Error initializing contract');
      }
    }
  }, [props.web3Provider]);

  const display = async (value) => {
    selectedWatches = [];
    props.setExchIds([]);
    setSelOption(value.value);
    if (!props.web3Provider) {
      setWatches(<div id="e-message">Please connect your wallet to view your watches.</div>);
    } else {
      const watches1 = [];
      let userinfo;
      try {
        userinfo = await axios.get(`https://api.covalenthq.com/v1/1/address/${props.address}/balances_v2/?quote-currency=USD&format=JSON&nft=true&no-nft-fetch=true&key=ckey_6c3514efa34e4ea6a62f2e95df5`);
      } catch (err) {
        console.dir(err);
      }
      if (value.value === '1 Freak + 1 Ceramic') {
        setOutput('1 Gold');
      } else if (value.value === '2 Ceramic') {
        setOutput('1 Steel');
      } else if (value.value === '4 Ceramic') {
        setOutput('1 Gold');
      } else if (value.value === '2 Steel') {
        setOutput('1 Gold');
      } else if (value.value === '3 Ceramic') {
        setOutput('1 Freak');
      }
      const tokenarray = userinfo.data.data.items;
      for (let i = 0; i < tokenarray.length; i += 1) {
        const token = tokenarray[i];
        if (token.contract_name === 'NIMANYClubWatches') {
          for (let j = 0; j < token.nft_data.length; j += 1) {
            const nft = token.nft_data[j];
            const metadata1 = metadata.meta_array[nft.token_id];

            if (value.value === '1 Freak + 1 Ceramic') {
              if (metadata1.attributes[12].value === 'Ceramic' || metadata1.attributes[12].value === 'Freaks') {
                watches1.push(metadata1);
              }
            } else if (value.value === '2 Ceramic') {
              if (metadata1.attributes[12].value === 'Ceramic') {
                watches1.push(metadata1);
              }
            } else if (value.value === '4 Ceramic') {
              if (metadata1.attributes[12].value === 'Ceramic') {
                watches1.push(metadata1);
              }
            } else if (value.value === '2 Steel') {
              if (metadata1.attributes[12].value === 'Steel') {
                watches1.push(metadata1);
              }
            } else if (value.value === '3 Ceramic') {
              if (metadata1.attributes[12].value === 'Ceramic') {
                watches1.push(metadata1);
              }
            }
          }
        }
      }

      if (watches1.length > 0) {
        setWatches(watches1.map((watch) => {
          return (
            <div key={watch.id} onClick={() => { select(watch.id, value.value); }}>
              <ExchangeTile code={watch} />
            </div>
          );
        }));
      } else {
        setWatches(<div id="e-message">We could not find any watches of this type for your address.</div>);
      }
    }
  };

  const burn = async () => {
    let estimatedGas, response, signature, exchType;
    const ids = JSON.parse(JSON.stringify(props.exch_ids));

    if (selOption === '1 Freak + 1 Ceramic') {
      exchType = 4;
      ids.push(0);
      ids.push(0);
    } else if (selOption === '2 Ceramic') {
      exchType = 0;
      ids.push(0);
      ids.push(0);
    } else if (selOption === '4 Ceramic') {
      exchType = 1;
    } else if (selOption === '2 Steel') {
      exchType = 4;
      ids.push(0);
      ids.push(0);
    } else if (selOption === '3 Ceramic') {
      exchType = 2;
      ids.push(0);
    }

    try {
      response = await axios.post('https://nimany.club/.netlify/functions/burn', {
        tokens: ids,
        address: props.address,
        exchType,
        selOption,
      });
      console.log(response);
      signature = response.data;
    } catch (error) {
      console.log(error);
    }

    try {
      estimatedGas = await contract.estimateGas.burnAndExchange(ids[0], ids[1], ids[2], ids[3], signature, exchType);
    } catch (err) {
      console.log(err.message);
      alert('Please insure you have selected a valid combination of watches.');
      return;
    }
    // console.log(estimatedGas);
    const gasLimit = estimatedGas.add(50000); // Add 50,000 to prevent failures from bad gas limit estimate.
    // eslint-disable-next-line one-var
    try {
      await contract.burnAndExchange(ids[0], ids[1], ids[2], ids[3], signature, exchType, { gasLimit });
    } catch (err) {
      alert('There was an issue burning.');
    }
  };

  return (
    <div id="maincontainer">
      <Nav />
      <div id="storycontainer">
        <div id="s-gray" />
        <div id="e-black" />
        <div id="e-bg">
          <div id="e-text-1">NIMANY NFT EXCHANGE</div>
          <div id="e-text-2">BURN</div>
          <Select
            styles={customStyles}
            onChange={display}
            options={options1}
          />
          <FontAwesomeIcon icon={['far', 'arrow-down-long']} size="1x" className="e-arrow" />
          <div id="e-watch-container">
            {watches}
          </div>
          <FontAwesomeIcon icon={['far', 'arrow-down-long']} size="1x" className="e-arrow" />
          <div id="e-text-2">RECIEVE</div>
          <div id="e-output">
            {output}
          </div>
          <FontAwesomeIcon icon={['far', 'arrow-down-long']} size="1x" className="e-arrow" />
          <div onClick={burn} id="burn-btn">
            BURN
          </div>
          <div id="s-footer-text">
            Copyright © 2022 NIMANY CLUB. All rights reserved.
          </div>
          <div id="s-footer-socials">
            <a href="https://twitter.com/nimany" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitter} size="1x" />
            </a>
            <a href="https://discord.gg/RywPVaPtye" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faDiscord} size="1x" />
            </a>
            <a href="https://www.clubhouse.com/@nimany" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={['far', 'hand-wave']} size="1x" />
            </a>
            <a href="https://www.instagram.com/nimany/?hl=en" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} size="1x" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => (
  {
    provider: state.web3.provider,
    web3Provider: state.web3.web3Provider,
    address: state.web3.address,
    chainId: state.web3.chainId,
    exch_ids: state.exchange.exch_ids,
  }
);

export default connect(mapStateToProps, { setExchIds })(Exchange);
