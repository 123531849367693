/* eslint-disable max-len */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import Nav from '../components/nav';

const Welcome = (props) => {
  return (
    <div id="maincontainer">
      <Nav />
      <div id="tos-container">
        <div id="tos-title">Terms & Conditions</div>
        <div className="toslist">- The exact date of the phases will be announced close to the phase.
        </div>
        <div className="toslist">- The Physical Watch will be randomly picked from the 11 color arrangements and materials.
        </div>
        <div className="toslist">- NIMANY Merchandise can only be shipped to USA, Canada, Europe and UAE. We can not ship to any other country. NFT Holders are only responsible for shipping fee and their own local taxes, the NIMANY Merchandise will be free of charge.
        </div>
        <div className="toslist">- A link containing the information of the the physical watch that will be shipped will be sent to the receiver&apos;s email address. A complete and accurate name, address, and phone number is needed for the shipment of the watch. Receiver is responsible for the shipping charges (depending on their location) and any import taxes set by the receiver&apos;s country.</div>
        <div className="toslist">- Physical Watch can not be returned for cash value, refunded or exchanged with any other physical or  digital product.
        </div>
        <div className="toslist">- The burning transaction for the physical watch is not reversible.
        </div>
        <div className="toslist">- If the shipment&apos;s tracking number indicates the watch/merchandise is delivered with the receiver&apos;s signature NIMANY can no longer be responsible for the loss or theft of the physical parcel. </div>
        <a href="https://etherscan.io/address/0x740e80CE6E3956733bC9833a74233C8fdD36F761#code" target="_blank" rel="noopener noreferrer">
          <div className="toslist">Contract Address: 0x740e80CE6E3956733bC9833a74233C8fdD36F761</div>
        </a>
      </div>
    </div>
  );
};

export default Welcome;
