/* eslint-disable no-constant-condition */
/* eslint-disable no-await-in-loop */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import metadata from '../files/metadata.json';
import GalleryTile from '../components/gallery/GalleryTile';
import Nav from '../components/nav';

const MyNFT = (props) => {
  const [codes, setCodes] = useState(false);

  useEffect(async () => {
    if (!props.web3Provider) {
      setCodes(<div id="mynftmessage">Please connect your wallet to view your watches.</div>);
    } else {
      const codes1 = [];
      let userinfo;
      try {
        userinfo = await axios.get(`https://api.covalenthq.com/v1/1/address/${props.address}/balances_v2/?quote-currency=USD&format=JSON&nft=true&no-nft-fetch=true&key=ckey_6c3514efa34e4ea6a62f2e95df5`);
      } catch (err) {
        console.dir(err);
      }
      const tokenarray = userinfo.data.data.items;
      for (let i = 0; i < tokenarray.length; i += 1) {
        const token = tokenarray[i];
        if (token.contract_name === 'NIMANYClubWatches') {
          for (let j = 0; j < token.nft_data.length; j += 1) {
            const nft = token.nft_data[j];
            const metadata1 = metadata.meta_array[nft.token_id];
            codes1.push(metadata1);
          }
        }
      }

      if (codes1.length > 0) {
        setCodes(codes1.map((code) => {
          return <GalleryTile key={code.id} code={code} />;
        }));
      } else {
        setCodes(<div id="mynftmessage">We could not find any watches for your address.</div>);
      }
    }
  }, [props.web3Provider]);

  return (
    <div>
      <Nav />
      <div className="mynfts" id="my-gallery-container">
        {codes}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => (
  {
    provider: state.web3.provider,
    web3Provider: state.web3.web3Provider,
    address: state.web3.address,
    chainId: state.web3.chainId,
  }
);

export default connect(mapStateToProps, null)(MyNFT);
