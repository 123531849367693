/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable camelcase */
import React from 'react';
import black_ceramic from '../files/black_ceramic.jpg';
import black_silicon from '../files/Black_Silicon.png';
import blue_ceramic from '../files/blue_ceramic.jpg';
import blue_silicon from '../files/blue_silicon.jpg';
import brown_tortise from '../files/brown_tortoise.jpg';
import butter_carbonfiber from '../files/butter_carbonfiber.jpg';
import coconut_jelly from '../files/coconut_jelly.jpg';
import coral_ceramic from '../files/coral_ceramic.jpg';
import coral_silicon from '../files/coral_silicon.jpg';
import cyber_green_ceramic from '../files/cyber_green_ceramic.jpg';
import cyber_zebra from '../files/cyber_zebra.jpg';
import grass_tortoise from '../files/grass_tortoise.jpg';
import green_carbonfiber from '../files/green_carbonfiber.jpg';
import green_ceramic from '../files/green_ceramic.jpg';
import green_silicon from '../files/green_silicon.jpg';
import ice_jelly from '../files/ice_jelly.jpg';
import lime_jelly from '../files/lime_jelly.jpg';
import orange_silicon from '../files/orange_silicon.jpg';
import pink_ceramic from '../files/pink_ceramic.jpg';
import pink_silicon from '../files/pink_silicon.jpg';
import psychadelic_red from '../files/psychadelic_red.jpg';
import purple_ceramic from '../files/purple_ceramic.jpg';
import purple_jelly from '../files/purple_jelly.jpg';
import purple_silicon from '../files/purple_silicon.jpg';
import red_tortoise from '../files/red_tortoise.jpg';
import stainless_steel from '../files/stainless_steel.jpg';
import tomb_stone from '../files/tomb_stone.jpg';
import turquoise_ceramic from '../files/turquoise_ceramic.jpg';
import turquoise_silicon from '../files/turquoise_silicon.jpg';
import white_ceramic from '../files/white_ceramic.jpg';
import White_Silicon from '../files/White_Silicon.png';
import Grey_Ceramic from '../files/Grey_Ceramic.png';
import wool_electricblue from '../files/wool_electricblue.jpg';
import wool_magenta from '../files/wool_magenta.jpg';
import yellow_ceramic from '../files/yellow_ceramic.jpg';
import yellow_gold from '../files/yellow_gold.jpg';
import yellow_silicon from '../files/yellow_silicon.jpg';
import zebra_carbonfiber from '../files/zebra_carbonfiber.jpg';

const Material = (props) => {
  let material, text;

  if (props.material === 'Blue Ceramic') {
    material = blue_ceramic;
    text = 'Luxurious Royal Blue Ceramic adds a touch of style to any combination. Bio Ceramic is a newly discovered and environmental friendly material. The real nature of Bio Ceramic is extremely unique because it allows for a wide range of colors and hues, resulting in a colorful yet luxurious style.';
  } else if (props.material === 'Blue Silicon') {
    material = blue_silicon;
    text = 'Silicon is a recently discovered material to be successfully adopted in the watch industry because of its comfort, look, water resistant character and most importantly ability to take any color mix. We have used the highest grade of silicon on our physical watches and here we have it on our NFT watches too.';
  } else if (props.material === 'Brown Tortoise') {
    material = brown_tortise;
    text = 'The most popular formation of tortoise often used in sunglasses. Simple yet strong sea shell white ceramic makes a modern statement. Tortoise is an organic and beautiful pattern created to resemble the shell of the turtle. The organic formation and the different transparency values of this material adds a depth and style to any luxury item.';
  } else if (props.material === 'Butter Carbon Fiber') {
    material = butter_carbonfiber;
    text = 'Butter Carbon fiber is a rare mix of Carbon Fiber. Carbon fiber is a newel discovered light but extremely playful material, often used in luxury timepieces. Here we have explored a new pattern formation of this famous material.';
  } else if (props.material === 'Coconut Jelly') {
    material = coconut_jelly;
    text = 'The most delicious looking watch case you have ever seen. Coconut flavored Jelly!. This is one of the rarest material. We have developed this metaverse special material to show the limitless imagination that can take place in NFTs. Imagine a watch with a jelly case!';
  } else if (props.material === 'Coral Ceramic') {
    material = coral_ceramic;
    text = 'Coral Pastel orange Ceramic adds an Art Deco Miami vibe to the mix. Bio Ceramic is a newly discovered and environmental friendly material. The real nature of Bio Ceramic is extremely unique because it allows for a wide range of colors and hues, resulting in a colorful yet luxurious style.';
  } else if (props.material === 'Coral Silicon') {
    material = coral_silicon;
    text = 'Silicon is a recently discovered material to be successfully adopted in the watch industry because of its comfort, look, water resistant character and most importantly ability to take any color mix. We have used the highest grade of silicon on our physical watches and here we have it on our NFT watches too.';
  } else if (props.material === 'Cyber Green' || props.material === 'Cyber Green Ceramic') {
    material = cyber_green_ceramic;
    text = 'Inspired by one of the vibrant colors of the ever growing genre of Cyber art. Bio Ceramic is a newly discovered and environmental friendly material. The real nature of Bio Ceramic is extremely unique because it allows for a wide range of colors and hues, resulting in a colorful yet luxurious style.';
  } else if (props.material === 'Cyber Zebra Carbon') {
    material = cyber_zebra;
    text = 'Cyber Zebra Carbon fiber is an extreme rare mix of Carbon Fiber. Carbon fiber is a newel discovered light but extremely playful material, often used in luxury timepieces. Here we have explored a new pattern formation of this famous material.';
  } else if (props.material === 'Grass Tortoise') {
    material = grass_tortoise;
    text = 'Our own interpretation of a material that doesn’t exist IRL. Simple yet strong sea shell white ceramic makes a modern statement. Tortoise is an organic and beautiful pattern created to resemble the shell of the turtle. The organic formation and the different transparency values of this material adds a depth and style to any luxury item.';
  } else if (props.material === 'Green Carbon Fiber') {
    material = green_carbonfiber;
    text = 'Green Carbon fiber is an extreme rare mix of Carbon Fiber. Carbon fiber is a newel discovered light but extremely playful material, often used in luxury timepieces. Here we have explored a new pattern formation of this famous material.';
  } else if (props.material === 'Green Ceramic') {
    material = green_ceramic;
    text = 'This Pastel Green Ceramic adds an earthly vibe to the mix. Bio Ceramic is a newly discovered and environmental friendly material. The real nature of Bio Ceramic is extremely unique because it allows for a wide range of colors and hues, resulting in a colorful yet luxurious style.';
  } else if (props.material === 'Green Silicon') {
    material = green_silicon;
    text = 'Silicon is a recently discovered material to be successfully adopted in the watch industry because of its comfort, look, water resistant character and most importantly ability to take any color mix. We have used the highest grade of silicon on our physical watches and here we have it on our NFT watches too.';
  } else if (props.material === 'Ice Jelly') {
    material = ice_jelly;
    text = 'The most delicious looking watch case you have ever seen. Ice flavored Jelly!. This is one of the rarest materials. We have developed this metaverse special material to show the limitless imagination that can take place in NFTs. Imagine a watch with a jelly case!';
  } else if (props.material === 'Lime Jelly') {
    material = lime_jelly;
    text = 'The most delicious looking watch case you have ever seen. Lime flavored Jelly!. This is one of the rarest materials. We have developed this metaverse special material to show the limitless imagination that can take place in NFTs. Imagine a watch with a jelly case!';
  } else if (props.material === 'Orange Silicon') {
    material = orange_silicon;
    text = 'Silicon is a recently discovered material to be successfully adopted in the watch industry because of its comfort, look, water resistant character and most importantly ability to take any color mix. We have used the highest grade of silicon on our physical watches and here we have it on our NFT watches too.';
  } else if (props.material === 'Pink Ceramic') {
    material = pink_ceramic;
    text = 'Pastel Pink Ceramic adds an Art Deco Miami vibe to the mix. Bio Ceramic is a newly discovered and environmental friendly material. The real nature of Bio Ceramic is extremely unique because it allows for a wide range of colors and hues, resulting in a colorful yet luxurious style.';
  } else if (props.material === 'Pink Silicon') {
    material = pink_silicon;
    text = 'Silicon is a recently discovered material to be successfully adopted in the watch industry because of its comfort, look, water resistant character and most importantly ability to take any color mix. We have used the highest grade of silicon on our physical watches and here we have it on our NFT watches too.';
  } else if (props.material === 'Psychedelic Red Ceramic') {
    material = psychadelic_red;
    text = 'This very unique and special red is among the very rare traits. Bio Ceramic is a newly discovered and environmental friendly material. The real nature of Bio Ceramic is extremely unique because it allows for a wide range of colors and hues, resulting in a colorful yet luxurious style.';
  } else if (props.material === 'Purple Ceramic') {
    material = purple_ceramic;
    text = 'Pastel Purple Ceramic adds an Art Deco Miami vibe to the mix. Bio Ceramic is a newly discovered and environmental friendly material. The real nature of Bio Ceramic is extremely unique because it allows for a wide range of colors and hues, resulting in a colorful yet luxurious style.';
  } else if (props.material === 'Grape Jelly') {
    material = purple_jelly;
    text = 'The most delicious looking watch case you have ever seen. Grape flavored Jelly!. This is one of the rarest materials. We have developed this metaverse special material to show the limitless imagination that can take place in NFTs. Imagine a watch with a jelly case!';
  } else if (props.material === 'Purple Silicon') {
    material = purple_silicon;
    text = 'Silicon is a recently discovered material to be successfully adopted in the watch industry because of its comfort, look, water resistant character and most importantly ability to take any color mix. We have used the highest grade of silicon on our physical watches and here we have it on our NFT watches too.';
  } else if (props.material === 'Red Tortoise') {
    material = red_tortoise;
    text = 'Sexiest formation of tortoise often used in handbags. Simple yet strong sea shell white ceramic makes a modern statement. Tortoise is an organic and beautiful pattern created to resemble the shell of the turtle. The organic formation and the different transparency values of this material adds a depth and style to any luxury item.';
  } else if (props.material === 'Steel') {
    material = stainless_steel;
    text = 'The solid Stainless Steel and its stylish character. You have a rare watch if your watch has a Stainless Steel part and you are getting close to the ultimate prize.';
  } else if (props.material === 'Tomb Stone') {
    material = tomb_stone;
    text = 'Something about an aged and sentimental stone. This is one of the rarest materials. We have developed this metaverse special material to show the limitless imagination that can take place in NFTs. Imagine a watch with a tomb stone case!';
  } else if (props.material === 'Turquoise Ceramic') {
    material = turquoise_ceramic;
    text = 'Pastel turquoise Ceramic adds an Art Deco Miami vibe to the mix. Bio Ceramic is a newly discovered and environmental friendly material. The real nature of Bio Ceramic is extremely unique because it allows for a wide range of colors and hues, resulting in a colorful yet luxurious style.';
  } else if (props.material === 'Turquoise Silicon') {
    material = turquoise_silicon;
    text = 'Silicon is a recently discovered material to be successfully adopted in the watch industry because of its comfort, look, water resistant character and most importantly ability to take any color mix. We have used the highest grade of silicon on our physical watches and here we have it on our NFT watches too.';
  } else if (props.material === 'White Ceramic') {
    material = white_ceramic;
    text = 'Simple yet strong sea shell white ceramic makes a modern statement. Bio Ceramic is a newly discovered and environmental friendly material. The real nature of Bio Ceramic is extremely unique because it allows for a wide range of colors and hues, resulting in a colorful yet luxurious style.';
  } else if (props.material === 'Blue Wool') {
    material = wool_electricblue;
    text = 'A true freak, this is a material that you will never see on a timepiece. We have developed this metaverse special material to show the limitless imagination that can take place in NFTs. Imagine a wool covered watch case!';
  } else if (props.material === 'Magenta Wool') {
    material = wool_magenta;
    text = 'A true freak, this is a material that you will never see on a timepiece. We have developed this metaverse special material to show the limitless imagination that can take place in NFTs. Imagine a wool covered watch case!';
  } else if (props.material === 'Yellow Ceramic') {
    material = yellow_ceramic;
    text = 'Pastel yellow Ceramic adds an Art Deco Miami vibe to the mix. Bio Ceramic is a newly discovered and environmental friendly material. The real nature of Bio Ceramic is extremely unique because it allows for a wide range of colors and hues, resulting in a colorful yet luxurious style.';
  } else if (props.material === 'Gold') {
    material = yellow_gold;
    text = 'The good old Solid Gold and its loud statements. You can not have luxury without Gold and its vibrant personality. Consider yourself super lucky if your watch has a Gold part. Now you can make your metaverse dream come to reality.';
  } else if (props.material === 'Yellow Silicon') {
    material = yellow_silicon;
    text = 'Silicon is a recently discovered material to be successfully adopted in the watch industry because of its comfort, look, water resistant character and most importantly ability to take any color mix. We have used the highest grade of silicon on our physical watches and here we have it on our NFT watches too.';
  } else if (props.material === 'Cyber Zebra Carbon') {
    material = zebra_carbonfiber;
    text = 'Zebra Carbon fiber is a chic black and white swirl Carbon Fiber. Carbon fiber is a newel discovered light but extremely playful material, often used in luxury timepieces. Here we have explored a new pattern formation of this famous material.';
  } else if (props.material === 'Black Ceramic') {
    material = black_ceramic;
    text = 'Deepest and most mysterious mix of Ceramics. Bio Ceramic is a newly discovered and environmental friendly material. The real nature of Bio Ceramic is extremely unique because it allows for a wide range of colors and hues, resulting in a colorful yet luxurious style.';
  } else if (props.material === 'Grey Ceramic') {
    material = Grey_Ceramic;
    text = 'A neutral tone of Grey Ceramic complements any other pairing color. Bio Ceramic is a newly discovered and environmental friendly material. The real nature of Bio Ceramic is extremely unique because it allows for a wide range of colors and hues, resulting in a colorful yet luxurious style.';
  } else if (props.material === 'White Silicon') {
    material = White_Silicon;
    text = 'Silicon is a recently discovered material to be successfully adopted in the watch industry because of its comfort, look, water resistant character and most importantly ability to take any color mix. We have used the highest grade of silicon on our physical watches and here we have it on our NFT watches too.';
  } else if (props.material === 'Black Silicon') {
    material = black_silicon;
    text = 'Silicon is a recently discovered material to be successfully adopted in the watch industry because of its comfort, look, water resistant character and most importantly ability to take any color mix. We have used the highest grade of silicon on our physical watches and here we have it on our NFT watches too.';
  }

  return (
    <div className="material-container">
      <img src={material} className="watch-material" />
      <div className="material-text">{text}</div>
    </div>

  );
};

export default Material;
