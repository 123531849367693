import React from 'react';
import Img from 'react-cool-img';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

const GalleryTile = (props) => {
  return (
    <NavLink to={`watch/${props.code.id}`} id="link" className="gallery-code-container">
      <Img
        className="gallery-img"
        src={`https://nimany-thumbs.s3.us-east-2.amazonaws.com/${props.code.id}.jpg`}
      />
      <div className="tile-name">
        {`${props.code.name}`}
      </div>
      <div className="tile-alignment">
        Type: {props.code.attributes[12].value}
      </div>
      <div className="bottom-banner">
        More Details
      </div>
    </NavLink>
  );
};

export default connect(null, null)(GalleryTile);
