/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import Fade from 'react-reveal/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter, faInstagram, faDiscord,
} from '@fortawesome/free-brands-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { ethers } from 'ethers';
import { ToastContainer, toast } from 'react-toastify';
import { setClaimIds } from '../actions';
import Nav from '../components/nav';
import metadata from '../files/metadata.json';
import abi from '../files/NIMANYClubWatches.json';
import ClaimTile from '../components/claim/ClaimTile';
import 'react-toastify/dist/ReactToastify.css';

const IS_PROD = true;
const CONTRACT_ADDRESS = IS_PROD ? '0x740e80CE6E3956733bC9833a74233C8fdD36F761' : '0xFF6F627dD2F29eedfD240982A17cF65704078345';

const customStyles = {
  content: {
    overflow: 'show',
    width: '270px',
    height: '300px',
    fontFamily: 'Roboto',
    top: '50%',
    left: '50%',
    right: 'auto',
    backgroundColor: '#FFFFFF',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '3px solid #C7F9F9',
    color: 'black',
  },
};

const Claim = (props) => {
  const [checkId, setCheckId] = useState('');
  const [match, setMatch] = useState(false);
  const [email1, setEmail1] = useState('');
  const [email2, setEmail2] = useState('');
  const [watches, setWatches] = useState(<div id="e-message">Please connect your wallet to view your watches.</div>);
  let selectedWatches = [];
  let contract;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalmessage, setMessage] = useState('Enter the token ID of the watch you would like to check. For example, to check on the status of watch #0084, enter the number 84.');

  const handleCheckIdChange = (event) => {
    setCheckId(event.target.value);
  };

  const handleEmailChange1 = (event) => {
    setEmail1(event.target.value);
    if (event.target.value === email2) {
      setMatch(true);
    } else {
      setMatch(false);
    }
  };

  const handleEmailChange2 = (event) => {
    setEmail2(event.target.value);
    if (email1 === event.target.value) {
      setMatch(true);
    } else {
      setMatch(false);
    }
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const afterOpenModal = async () => {
    // references are now sync'd and can be accessed.
  };

  const select = (id) => {
    if (selectedWatches.length < 1) {
      selectedWatches.push(id);
      const arrcopy = JSON.parse(JSON.stringify(selectedWatches));
      props.setClaimIds(arrcopy);
    } else if (!selectedWatches.includes(id)) {
      selectedWatches.shift();
      selectedWatches.push(id);

      const arrcopy = JSON.parse(JSON.stringify(selectedWatches));
      props.setClaimIds(arrcopy);
    }
  };

  if (props.web3Provider) {
    try {
      contract = new ethers.Contract(
        CONTRACT_ADDRESS,
        abi.abi,
        props.web3Provider.getSigner(),
      );
    } catch (err) {
      console.log(err);
      console.error('Error initializing contract');
    }
  }

  useEffect(async () => {
    selectedWatches = props.claim_ids;

    if (!props.web3Provider) {
      setWatches(<div id="e-message">Please connect your wallet to view your watches.</div>);
    } else {
      try {
        contract = new ethers.Contract(
          CONTRACT_ADDRESS,
          abi.abi,
          props.web3Provider.getSigner(),
        );
      } catch (err) {
        console.log(err);
        console.error('Error initializing contract');
      }

      const watches1 = [];
      let userinfo;
      try {
        userinfo = await axios.get(`https://api.covalenthq.com/v1/1/address/${props.address}/balances_v2/?quote-currency=USD&format=JSON&nft=true&no-nft-fetch=false&key=ckey_6c3514efa34e4ea6a62f2e95df5`);
      } catch (err) {
        console.dir(err);
      }
      const tokenarray = userinfo.data.data.items;
      for (let i = 0; i < tokenarray.length; i += 1) {
        const token = tokenarray[i];
        if (token.contract_name === 'NIMANYClubWatches') {
          for (let j = 0; j < token.nft_data.length; j += 1) {
            const nft = token.nft_data[j];
            const metadata1 = metadata.meta_array[nft.token_id];
            if (metadata1.attributes[12].value === 'Gold') {
              watches1.push(metadata1);
            }
          }
        }
      }

      if (watches1.length > 0) {
        setWatches(watches1.map((watch) => {
          return (
            <div key={watch.id} onClick={() => { select(watch.id); }}>
              <ClaimTile code={watch} />
            </div>
          );
        }));
      } else {
        setWatches(<div id="e-message">We could not find any watches of this type for your address.</div>);
      }
    }
  }, [props.web3Provider]);

  const claim = async () => {
    let estimatedGas, response, signature, claimedresponse;
    const tokenid = props.claim_ids[0];
    console.log(tokenid);
    try {
      claimedresponse = await axios.post('/.netlify/functions/findclaimrecord', {
        tokenid: checkId,
      });
      console.log(claimedresponse);
    } catch (error) {
      console.log(error);
    }

    if (claimedresponse.data === 'claimed') {
      toast('This watch has already been claimed', {
        hideProgressBar: true,
      });
    } else {
      try {
        response = await axios.post('/.netlify/functions/claim', {
          tokenid,
          address: props.address,
        });
        console.log(response);
        signature = response.data;
      } catch (error) {
        console.log(error);
      }

      try {
        estimatedGas = await contract.estimateGas.claimGold(tokenid, signature);
      } catch (err) {
        console.log(err.message);
        alert('Please insure you have selected a valid combination of watches.');
        return;
      }
      // console.log(estimatedGas);
      const gasLimit = estimatedGas.add(50000); // Add 50,000 to prevent failures from bad gas limit estimate.
      // eslint-disable-next-line one-var
      try {
        await contract.claimGold(tokenid, signature, { gasLimit });
      } catch (err) {
        alert('There was an issue claiming.');
        return;
      }

      try {
        response = await axios.post('/.netlify/functions/createclaimrecord', {
          tokenid,
          email: email1,
        });
        console.log(response);
        if (response.data === 'recordcreated') {
          toast('Claim successful! Please wait to be contacted with further instructions.', {
            hideProgressBar: true,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const checkclaim = async () => {
    let response;
    try {
      response = await axios.post('/.netlify/functions/findclaimrecord', {
        tokenid: checkId,
      });
      console.log(response);
    } catch (error) {
      console.log(error);
    }

    if (response.data === 'unclaimed') {
      setMessage('This watch has not yet been claimed');
    } else if (response.data === 'claimed') {
      setMessage('This NFT has already been used to claim a physical watch');
    } else {
      setMessage('There was an error checking the status of this watch.');
    }
  };

  return (
    <div id="maincontainer">
      <Nav />
      <div id="storycontainer">
        <div id="s-gray" />
        <div id="e-black" />
        <div id="e-bg">
          <ToastContainer />
          <div id="e-text-1">NIMANY NFT CLAIM</div>
          <div onClick={openModal} id="burn-btn">
            CHECK CLAIM STATUS
          </div>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            onAfterOpen={afterOpenModal}
            style={customStyles}
            ariaHideApp={false}
            overlayClassName="Overlay"
          >
            <div id="modalcontainer">
              <div id="modalclose" onClick={closeModal}>
                <FontAwesomeIcon icon={faTimes} size="lg" />
              </div>
              <div id="modalmessagetext">
                {modalmessage}
              </div>
              <input className="claim-input" placeholder="Token ID" type="text" value={checkId} onChange={handleCheckIdChange} />
              <div role="button" id="mintreal" tabIndex={-1} onClick={checkclaim} className="mintbutton">
                CHECK IF CLAIMED
              </div>
            </div>
          </Modal>
          <div id="e-watch-container">
            {watches}
          </div>
          <FontAwesomeIcon icon={['far', 'arrow-down-long']} size="1x" className="e-arrow" />
          <div id="c-email-container">
            <div id="c-email">
              Enter your email. Once claimed, this email will be the only way to redeem your physical watch, so please insure correctness.
            </div>
            <input className="email-input" placeholder="Email" type="text" value={email1} onChange={handleEmailChange1} />
            <input className="email-input" placeholder="Email" type="text" value={email2} onChange={handleEmailChange2} />
            <Fade bottom
              collapse
              when={match}
              style={{ position: 'absolute', bottom: '-16px' }}
            >
              <div id="c-confirmation"
                style={{ display: 'block' }}
              >
                Thank you! We’ll be in touch with you soon.
              </div>
            </Fade>
            <Fade bottom
              collapse
              when={!match}
              style={{ position: 'absolute', bottom: '-16px' }}
            >
              <div id="c-error"
                style={{ display: 'block' }}
              >
                Emails do not match.
              </div>
            </Fade>
          </div>
          <FontAwesomeIcon icon={['far', 'arrow-down-long']} size="1x" className="e-arrow" />
          {(match) ? (
            <div onClick={claim} id="burn-btn">
              CLAIM
            </div>
          ) : (
            <div id="opaque-claim-btn">
              CLAIM
            </div>
          )}
          <div id="s-footer-text">
            Copyright © 2022 NIMANY CLUB. All rights reserved.
          </div>
          <div id="s-footer-socials">
            <a href="https://twitter.com/nimany" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitter} size="1x" />
            </a>
            <a href="https://discord.gg/RywPVaPtye" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faDiscord} size="1x" />
            </a>
            <a href="https://www.clubhouse.com/@nimany" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={['far', 'hand-wave']} size="1x" />
            </a>
            <a href="https://www.instagram.com/nimany/?hl=en" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} size="1x" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => (
  {
    provider: state.web3.provider,
    web3Provider: state.web3.web3Provider,
    address: state.web3.address,
    chainId: state.web3.chainId,
    claim_ids: state.exchange.claim_ids,
  }
);

export default connect(mapStateToProps, { setClaimIds })(Claim);
